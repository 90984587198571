import { Window } from "@progress/kendo-react-dialogs";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReportViewer from "../sys-toolbox/ReportViewer";

class OrderRPAReport extends Component {
  render() {
    return (
      <Window
        title={this.props.title}
        onClose={this.props.toggleDialog}
        initialHeight={"85vh"}
        initialWidth={"95%"}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <ReportViewer
          reportSource={{
            report: "DEX/RPAReport.trdp",
            parameters: {
              teklif_siparis: `SIPARIS`,
              teklif_siparis_id: this.props.orderId,
            },
          }}
          openPopup={true}
        ></ReportViewer>
      </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(OrderRPAReport);
