import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { BASE_URL } from "../../config/globalVariables";
import Complaint from "../cmp-complaintManagement/Complaint";
import Homepage from "../cmp-homepage/HomePage";
import OrderList from "../cmp-order/OrderList";
import OrderUpdate from "../cmp-order/OrderUpdate";
import StockInquiryDealer from "../cmp-stockInquiry/StockInqueryDealer";
import StockInquiry from "../cmp-stockInquiry/StockInquiry";
import NotFound from "../sys-common/NotFound";
import Logout from "./Logout";
import ResetPassword from "./ResetPassword";
// import Smarty360 from "../cmp-smarty360/Smarty360";
import AnnouncementList from "../cmp-announcement/AnnouncementList";
import BiggSerialsModified from "../cmp-biggSerialsModified";
import BrandList from "../cmp-brand/BrandList";
import { DealerProductGroupList } from "../cmp-bryDealerProductGroup/DealerProductGroupList";
import DiscountDetail from "../cmp-bryDiscountManagment/DiscountDetail";
import { Sales } from "../cmp-brySales/Sales";
import Campaign from "../cmp-campaign/Campaign";
import CampaignDetail from "../cmp-campaign/CampaignDetail";
import { CustomerList } from "../cmp-customer/CustomerList";
import { CustomerUpdate } from "../cmp-customer/CustomerUpdate";
import CustomerVisitList from "../cmp-customerVisit/CustomerVisitList";
import DasuList from "../cmp-dasu/DasuList";
import DiscountApproveMatrixList from "../cmp-discountApproveMatrix/DiscountApproveMatrixList";
import DBSList from "../cmp-fin-offset/DBSList";
import GeneralExcelUpload from "../cmp-generalExcelUpload/GeneralExcelUpload";
import { HizTesti } from "../cmp-hizTesti/HizTesti";
import { SerialNumber } from "../cmp-izlenebilirlikSeriNo/SerialNumber";
import DealerProposerList from "../cmp-mrcDealerProposer/DealerProposerList";
import { ProductBomList } from "../cmp-mrcProductBom/ProductBomList";
import SalesDepartmentList from "../cmp-mrcSalesDepartment/SalesDepartmentList";
import SalesRepresentativeManagerList from "../cmp-mrcSalesRepresentativeManager/SalesRepresentativeManagerList";
import { OrderCopList } from "../cmp-order-cop/OrderCopList";
import OrderCopUpdate from "../cmp-order-cop/OrderCopUpdate";
import OrderApproval from "../cmp-order/OrderApproval";
import OrderListDealer from "../cmp-order/OrderListDealer";
import OrderUpdateDealer from "../cmp-order/OrderUpdateDealer";
import ProductList from "../cmp-product/ProductList";
import ProductPriceList from "../cmp-productPrice/ProductPriceList";
import { ProjectReport } from "../cmp-projectReport/ProjectReport";
import SalesApprovalList from "../cmp-salesApproval/SalesApprovalList";
import SelfServis from "../cmp-selfServis/SelfServis";
import { UpgReport } from "../cmp-upgReport/UpgReport";
import { UpgReportManager } from "../cmp-upgReportManager/UpgReportManager";
import { UrunTanimFiyatExcelLogList } from "../cmp-urunTanimFiyatExcelLog/UrunTanimFiyatExcelLogList";
import { UserList } from "../cmp-user/UserList";
import UserProjectResponsibilityList from "../cmp-userProjectResponsibility/UserProjectResponsibilityList";
import UserRights from "../cmp-userRights/UserRights";
import { ExpiryReport } from "../cmp-vadeEslemeReportManager/ExpiryReport";
import OfferDenvDetail from "../mercury/cmp-offer-denv-approve/OfferDenvDetail";
import OfferDenvList from "../mercury/cmp-offer-denv-approve/OfferDenvList";
import ProtectedRoute from "../sys-toolbox/ProtectedRoute";
import FreeStock from "../cmp-freeStock/FreeStock";
import FreeStockPutoLog from "../cmp-freeStock/FreeStockPutoLog";
import ProductArtiGunList from "../cmp-product/ProductArtiGunList";
import FreeStockReserved from "../cmp-freeStock/FreeStockReservedYedek";
import { MyOrderList } from "../cmp-order-my/MyOrderList";
import OrderDateApproveDetail from "../cmp-order/OrderDateApproveDetail";
import { OrderDateApproveList } from "../cmp-order/OrderDateApproveList";

class Routes extends Component {
  render() {
    return (
      <div className="content-wrapper">
        <div className="content">
          <Switch>
            <Route
              exact={true}
              path={BASE_URL}
              render={(props) => <Homepage {...props} />}
              // render={() => <Redirect to={BASE_URL + "homepage"} />}
            />
            <Route
              exact={true}
              path={BASE_URL + "homepage"}
              render={(props) => <Homepage {...props} />}
            />
            <Route
              exact={true}
              path={BASE_URL + "free-stock"}
              render={(props) => <FreeStock {...props} />}
            />
            <Route
              exact={true}
              path={BASE_URL + "Logout"}
              render={(props) => <Logout {...props} />}
            />
            <Route
              exact={true}
              path={BASE_URL + "Login"}
              render={() => <Redirect to={BASE_URL} />}
            />
            <Route
              exact={true}
              path={BASE_URL + "reset-password/:uid/:email"}
              render={(props) => <ResetPassword {...props} />}
            />

            <ProtectedRoute
              exact={true}
              path={BASE_URL + "fin-expiry-report"}
              protectedPath={BASE_URL + "fin-expiry-report"}
              component={ExpiryReport}
            />

            <ProtectedRoute
              exact={true}
              path={BASE_URL + "bigg-serials-modified-report"}
              protectedPath={BASE_URL + "bigg-serials-modified-report"}
              component={BiggSerialsModified}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "hiz-testi"}
              protectedPath={BASE_URL + "hiz-testi"}
              component={HizTesti}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "stock-inquiry"}
              protectedPath={BASE_URL + "stock-inquiry"}
              component={StockInquiry}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "stock-inquiry-dealer"}
              protectedPath={BASE_URL + "stock-inquiry-dealer"}
              component={StockInquiryDealer}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "dasu-product-list"}
              protectedPath={BASE_URL + "dasu-product-list"}
              component={DasuList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "complaint"}
              protectedPath={BASE_URL + "complaint"}
              component={Complaint}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "complaint-bayi"}
              protectedPath={BASE_URL + "complaint-bayi"}
              component={Complaint}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "my-orders"}
              protectedPath={BASE_URL + "my-orders"}
              component={MyOrderList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "order"}
              protectedPath={BASE_URL + "order"}
              component={OrderList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "order-dealer"}
              protectedPath={BASE_URL + "order-dealer"}
              component={OrderListDealer}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "order-update/:orderId"}
              protectedPath={BASE_URL + "order-update"}
              component={OrderUpdate}
            />
            {/* <Route
              exact={true}
              path={BASE_URL + "order-date-approve-list"}
              protectedPath={BASE_URL + "order-date-approve-list"}
              component={OrderDateApproveList}
            /> */}
            {/* //TODO: ProtectedRoute olarak Değişecek */}
            <Route
              exact={true}
              path={BASE_URL + "order-date-approve-detail/:orderDateApproveId"}
              protectedPath={BASE_URL + "order-date-approve-detail"}
              component={OrderDateApproveDetail}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "order-update-dealer/:orderId"}
              protectedPath={BASE_URL + "order-update-dealer"}
              component={OrderUpdateDealer}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "order-approval/:id/:satisonayid"}
              protectedPath={BASE_URL + "order-approval"}
              component={OrderApproval}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "campaign"}
              protectedPath={BASE_URL + "campaign"}
              component={Campaign}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "campaign-detail/:id"}
              protectedPath={BASE_URL + "campaign-detail"}
              component={CampaignDetail}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "product-price"}
              protectedPath={BASE_URL + "product-price"}
              component={ProductPriceList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "user-project-responsibility"}
              protectedPath={BASE_URL + "user-project-responsibility"}
              component={UserProjectResponsibilityList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "users"}
              protectedPath={BASE_URL + "users"}
              component={UserList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "dealer-proposer"}
              protectedPath={BASE_URL + "dealer-proposer"}
              component={DealerProposerList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "sales-department"}
              protectedPath={BASE_URL + "sales-department"}
              component={SalesDepartmentList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "sales-representative-manager"}
              protectedPath={BASE_URL + "sales-representative-manager"}
              component={SalesRepresentativeManagerList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "sales-approval"}
              protectedPath={BASE_URL + "sales-approval"}
              component={SalesApprovalList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "product-bom"}
              protectedPath={BASE_URL + "product-bom"}
              component={ProductBomList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "product"}
              protectedPath={BASE_URL + "product"}
              component={ProductList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "user-rights"}
              protectedPath={BASE_URL + "user-rights"}
              component={UserRights}
            />
            <Route
              exact={true}
              path={BASE_URL + "offset-list"}
              render={(props) => <DBSList {...props} />}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "self-servis"}
              protectedPath={BASE_URL + "self-servis"}
              component={SelfServis}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "order-discount-rates"}
              protectedPath={BASE_URL + "order-discount-rates"}
              component={DiscountDetail}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "general-excel-upload"}
              protectedPath={BASE_URL + "general-excel-upload"}
              component={GeneralExcelUpload}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "customer-list"}
              protectedPath={BASE_URL + "customer-list"}
              component={CustomerList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "customer-update/:customerId"}
              protectedPath={BASE_URL + "customer-update"}
              component={CustomerUpdate}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "order-cop-list"}
              protectedPath={BASE_URL + "order-cop-list"}
              component={OrderCopList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "order-cop-update/:orderId"}
              protectedPath={BASE_URL + "order-cop-update"}
              component={OrderCopUpdate}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "project-report"}
              protectedPath={BASE_URL + "project-report"}
              component={ProjectReport}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "upg-report"}
              protectedPath={BASE_URL + "upg-report"}
              component={UpgReport}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "upg-report-manager"}
              protectedPath={BASE_URL + "upg-report-manager"}
              component={UpgReportManager}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "discount-approve-matrix"}
              protectedPath={BASE_URL + "discount-approve-matrix"}
              component={DiscountApproveMatrixList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "dealer-product-group"}
              protectedPath={BASE_URL + "dealer-product-group"}
              component={DealerProductGroupList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "urun-fiyat-excel-gunceleme-log/:procId"}
              protectedPath={BASE_URL + "urun-fiyat-excel-gunceleme-log"}
              component={UrunTanimFiyatExcelLogList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "sales-scorecard"}
              protectedPath={BASE_URL + "sales-scorecard"}
              component={Sales}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "offer-cis-list"}
              protectedPath={BASE_URL + "offer-cis-list"}
              component={OfferDenvList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "offer-cis-approval/:offerid"}
              protectedPath={BASE_URL + "offer-cis-approval"}
              component={OfferDenvDetail}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "brand-list"}
              protectedPath={BASE_URL + "brand-list"}
              component={BrandList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "customer-visit-list"}
              protectedPath={BASE_URL + "customer-visit-list"}
              component={CustomerVisitList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "izlenebilirlik-serial-no"}
              protectedPath={BASE_URL + "izlenebilirlik-serial-no"}
              component={SerialNumber}
            />
            <Route
              exact={true}
              path={BASE_URL + "free-stock-puto-log"}
              // protectedPath={BASE_URL + "free-stock-puto-log"}
              component={FreeStockPutoLog}
            />
            <Route
              exact={true}
              path={BASE_URL + "free-stock-reserved"}
              // protectedPath={BASE_URL + "free-stock-puto-log"}
              component={FreeStockReserved}
            />
            <Route
              exact={true}
              path={BASE_URL + "announcement-list"}
              protectedPath={BASE_URL + "announcement-list"}
              component={AnnouncementList}
            />
            <ProtectedRoute
              exact={true}
              path={BASE_URL + "product-arti-gun"}
              protectedPath={BASE_URL + "product-arti-gun"}
              component={ProductArtiGunList}
            />
            <Route render={(props) => <NotFound {...props} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Routes);
