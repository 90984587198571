import { Button } from "@progress/kendo-react-buttons";
import jwtDecode from "jwt-decode";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PROJECT_CODE } from "../../config/globalVariables";
// import "../../css/login.css";
import {
  CheckEmail,
  defaultAlertMessages,
  getConfirmErrorMessage,
  parseQueryString,
} from "../../helpers/utils";
import {
  changeMfa,
  changeUser,
  forgotPassword,
  login,
  loginMfa,
  logout,
  resetPassword,
  setShowForm,
  updatePassword,
} from "../../redux/actions/act-root/authActions";
import {
  resetLogin,
  setLogin,
} from "../../redux/actions/act-root/loginActions";
import CapsLock from "../sys-toolbox/CapsLock";
import LanguageSelect from "../sys-toolbox/LanguageSelect";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import renderTime from "../sys-toolbox/Timer";
import Layout from "./Layout";
import LoginAAD from "./LoginAAD";
import { Checkbox } from "@progress/kendo-react-inputs";

class LoginAnimation extends Component {
  componentDidMount() {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      this.setUser(token);
    }
  }

  setUser = (token) => {
    if (this.props.auth.userName === "") {
      this.props.changeUser(jwtDecode(token));
    }
  };

  onChange = (event) => {
    this.props.setLogin(event);
  };

  setShowForm = (event) => {
    this.props.setShowForm();
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { email, password, mfa } = this.props.loginReducer._login;
    this.props.login(email, password, mfa, PROJECT_CODE);
  };

  onComplete = (e) => {
    this.props.resetLogin();
    this.props.changeMfa();
  };

  logout = (e) => {
    this.props.logout();
  };

  verify = (e) => {
    e.preventDefault();
    const { email, password, verificationCode, mfa } =
      this.props.loginReducer._login;
    this.props.loginMfa(email, password, verificationCode, mfa, PROJECT_CODE);
  };

  approveKVKK = (e) => {
    e.preventDefault();
    const { email, password } = this.props.loginReducer._login;
    this.props.approveKVKK(email, password, null, PROJECT_CODE, true); // KVKK onayı ile
  };

  forgotPassword = (e) => {
    e.preventDefault();
    const { email } = this.props.loginReducer._login;
    if (email === "") {
      defaultAlertMessages.MessageDetailError =
        "E-Mail alanını boş bırakmayınız";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else if (!CheckEmail(email)) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen geçerli bir mail adresi giriniz";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.props.forgotPassword(email);
    }
  };

  resetPassword = (e) => {
    e.preventDefault();

    var params = parseQueryString();
    const { resetPassword, resetPasswordAgain } =
      this.props.loginReducer._login;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%^&*.])(?=.{8,})"
    );
    if (resetPassword === null || !strongRegex.test(resetPassword)) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen içerisinde en az bir büyük harf,bir rakam, bir özel karakter bulunan ve en az 8 karakterden oluşan yeni şifrenizi giriniz.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else if (resetPassword !== resetPasswordAgain) {
      defaultAlertMessages.MessageDetailError =
        "Şifre ve Şifre Tekrar alanları aynı olmalıdır";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.props.resetPassword(
        params["uid"],
        parseInt(params["kid"]),
        resetPassword,
        resetPasswordAgain
      );
    }
  };

  updatePassword = (e) => {
    e.preventDefault();
    const { oldPassword, resetPassword, resetPasswordAgain } =
      this.props.loginReducer._login;
    const { mail } = this.props.auth;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%^&*.])(?=.{8,})"
    );
    if (resetPassword === null || !strongRegex.test(resetPassword)) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen içerisinde en az bir büyük harf,bir rakam, bir özel karakter bulunan ve en az 8 karakterden oluşan yeni şifrenizi giriniz.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else if (resetPassword !== resetPasswordAgain) {
      defaultAlertMessages.MessageDetailError =
        "Şifre ve Şifre Tekrar alanları aynı olmalıdır";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else if (oldPassword === null || oldPassword == "") {
      defaultAlertMessages.MessageDetailError = "Eski şifreniz boş geçilemez.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.props.updatePassword(mail, oldPassword, resetPassword);
    }
  };

  render() {
    const { t } = this.props;
    if (this.props.auth.isMfa && !this.props.auth.isAuthenticated) {
      if (this.props.auth.isKVKKApprovePending) {
        return (
          <div className="loginBg" style={{ overflow: "hidden" }}>
            {this.props.auth.fetching && LoadingPanel}
            <LanguageSelect />
            <form id="kvkk-approval-form" onSubmit={this.approveKVKK}>
              <h3>{t("kvkk_approval_needed")}</h3>
              {/* KVKK onay maddeleri */}
              <div className="inputGroup inputGroup1">
                <Checkbox id={"chb1"}>
                  <label
                    htmlFor={"chb1"}
                    className={"k-checkbox-label"}
                    style={{
                      display: "inline",
                      fontSize: "0.8em",
                    }}
                  >
                    <a target="_blank" href="/kvkk/Dekorasyon_Yonetimi.pdf">
                      Dekorasyon Yönetimi
                    </a>
                    'nde belirtilen hususları okudum ve kabul ediyorum.
                  </label>
                </Checkbox>
              </div>
              <div className="inputGroup inputGroup1">
                <Checkbox id={"chb1"}>
                  <label
                    htmlFor={"chb1"}
                    className={"k-checkbox-label"}
                    style={{
                      display: "inline",
                      fontSize: "0.8em",
                    }}
                  >
                    <a
                      target="_blank"
                      href="/kvkk/Is_Ortaklari_Egitim_Seminer_ve_Sertifika_Surecleri.pdf"
                    >
                      İş Ortakları Eğitim ve Sertifika Süreçleri
                    </a>
                    'nde belirtilen hususları okudum ve kabul ediyorum.
                  </label>
                </Checkbox>
              </div>
              <div className="inputGroup inputGroup1">
                <Checkbox id={"chb1"}>
                  <label
                    htmlFor={"chb1"}
                    className={"k-checkbox-label"}
                    style={{
                      display: "inline",
                      fontSize: "0.8em",
                    }}
                  >
                    <a target="_blank" href="/kvkk/Memnuniyet_Yonetimi.pdf">
                      Memnuniyet Yönetimi
                    </a>
                    'nde belirtilen hususları okudum ve kabul ediyorum.
                  </label>
                </Checkbox>
              </div>
              <div className="inputGroup inputGroup1">
                <Checkbox id={"chb1"}>
                  <label
                    htmlFor={"chb1"}
                    className={"k-checkbox-label"}
                    style={{
                      display: "inline",
                      fontSize: "0.8em",
                    }}
                  >
                    <a
                      target="_blank"
                      href="/kvkk/Sirket_Operasyonlarina_Yonelik_Surec_Gelistirme_ve_Dijitallesme_Yonetimi.pdf"
                    >
                      Şirket Operasyonlarına Yönetlik Süreç Geliştirme ve
                      Dijitalleşme Yönetimi
                    </a>
                    'nde belirtilen hususları okudum ve kabul ediyorum.
                  </label>
                </Checkbox>
              </div>
              <div className="inputGroup inputGroup1">
                <Checkbox id={"daikin_is_ortakligi"}>
                  <label
                    htmlFor={"chb1"}
                    className={"k-checkbox-label"}
                    style={{
                      display: "inline",
                      fontSize: "0.8em",
                    }}
                  >
                    <a
                      target="_blank"
                      href="/kvkk/Daikin_Is_Ortakligi_Aydinlatma_Metni.pdf"
                    >
                      Daikin İş Ortaklığı Aydınlatma Metni
                    </a>
                    'nde belirtilen hususları okudum ve kabul ediyorum.
                  </label>
                </Checkbox>
              </div>
              <div className="inputGroup inputGroup1">
                <Checkbox id={"daikin_is_ortakligi"}>
                  <label
                    htmlFor={"chb1"}
                    className={"k-checkbox-label"}
                    style={{
                      display: "inline",
                      fontSize: "0.8em",
                    }}
                  >
                    <a
                      target="_blank"
                      href="/kvkk/Surdurulebilirlik_ve_Proje_Yonetimi_Takibi_ve_Raporlama_Yonetimi.pdf"
                    >
                      Sürdürülebilirlik ve Proje Yönetimi Takibi ve Raporlama
                      Yönetimi
                    </a>
                    'nde belirtilen hususları okudum ve kabul ediyorum.
                  </label>
                </Checkbox>
              </div>
              <div className="inputGroup inputGroup2">
                <button
                  type="button"
                  id="approveKVKK"
                  onClick={this.approveKVKK}
                >
                  {t("approve")}
                </button>
              </div>
            </form>
          </div>
        );
      } else {
        return (
          <div className="loginBg" style={{ overflow: "hidden" }}>
            {this.props.auth.fetching && LoadingPanel}
            <LanguageSelect></LanguageSelect>

            <form
              id="style-14"
              autoComplete="new-password"
              onSubmit={this.onSubmit}
            >
              <div className="row">
                <div className="col-12 dexWord">
                  <h1>
                    <img
                      id="idLogoHeader"
                      alt=""
                      src={
                        window.location.origin +
                        process.env.PUBLIC_URL +
                        "/dex_logo.png"
                      }
                    />
                  </h1>
                </div>
              </div>
              <div>
                <div>
                  <CountdownCircleTimer
                    strokeWidth={14}
                    isPlaying
                    duration={180}
                    colors={[["#00aeef", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                    onComplete={this.onComplete}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                </div>
                <div className="inputGroup inputGroup1">
                  <label htmlFor="verfyCode1">{t("verificationCode")}</label>
                  <input
                    type="password"
                    id="verificationCode"
                    name="verificationCode"
                    onFocus={this.onMfaFocus}
                    onBlur={this.onMfaBlur}
                    className="verificationCode"
                    maxLength="6"
                    autoComplete="new-password"
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    value={this.props.loginReducer._login.verificationCode}
                  />
                  <span className="indicator"></span>
                </div>
                <div className="inputGroup inputGroup2">
                  <button
                    type="button"
                    id="verify"
                    onClick={this.verify}
                    style={{ backgroundColor: "#cd4c6e" }}
                  >
                    {t("verify")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        );
      }
    } else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.lastPasswordChange &&
      this.props.auth.isDaikin !== "DAIKIN"
    ) {
      return (
        <div className="loginBg" style={{ overflow: "hidden" }}>
          {this.props.auth.fetching && LoadingPanel}
          <LanguageSelect></LanguageSelect>

          <form
            id="style-14"
            autoComplete="new-password"
            onSubmit={this.onSubmit}
          >
            <div className="row">
              <div className="col-12 dexWord">
                <h1>
                  <img
                    id="idLogoHeader"
                    alt=""
                    src={
                      window.location.origin +
                      process.env.PUBLIC_URL +
                      "/dex_logo.png"
                    }
                  />
                </h1>
              </div>
            </div>
            <div>
              <div className="inputGroup inputGroup1">
                <label htmlFor="oldPassword">{t("oldPassword")}</label>
                <input
                  type="password"
                  name="oldPassword"
                  required
                  onFocus={this.onPasswordFocus}
                  onBlur={this.onPasswordBlur}
                  id="oldPassword"
                  className="password"
                  onChange={this.onChange}
                />
                <span className="indicator"></span>
              </div>
              <div className="inputGroup inputGroup1">
                <label htmlFor="resetPassword">{t("password")}</label>
                <input
                  type="password"
                  name="resetPassword"
                  required
                  onFocus={this.onPasswordFocus}
                  onBlur={this.onPasswordBlur}
                  id="resetPassword"
                  className="password"
                  onChange={this.onChange}
                />
                <span className="indicator"></span>
              </div>
              <div className="inputGroup inputGroup2">
                <label htmlFor="password">{t("password_again")}</label>
                <input
                  type="password"
                  name="resetPasswordAgain"
                  required
                  onFocus={this.onPasswordFocus}
                  onBlur={this.onPasswordBlur}
                  id="resetPasswordAgain"
                  className="password"
                  onChange={this.onChange}
                />
              </div>
              <div className="inputGroup inputGroup2">
                <button
                  type="button"
                  id="verify"
                  onClick={this.updatePassword}
                  style={{ backgroundColor: "#cd4c6e" }}
                >
                  {t("change")}
                </button>
              </div>
              <div>
                <label style={{ fontSize: "14px", fontStyle: "italic" }}>
                  {t("outdatedPassword")}
                </label>
              </div>
              <div style={{ margin: 80 }} className="inputGroup inputGroup2">
                <button
                  type="button"
                  id="turnLogin"
                  onClick={this.logout}
                  style={{ backgroundColor: "#9ca3af" }}
                >
                  <i class="fas fa-arrow-left"></i> {t("turnLogin")}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (window.location.pathname === "/reset-password") {
      return (
        <div className="loginBg" style={{ overflow: "hidden" }}>
          {this.props.auth.fetching && LoadingPanel}
          <LanguageSelect></LanguageSelect>

          <form
            id="style-14"
            autoComplete="new-password"
            onSubmit={this.onSubmit}
          >
            <div className="row">
              <div className="col-12 dexWord">
                <h1>
                  <img
                    id="idLogoHeader"
                    alt=""
                    src={
                      window.location.origin +
                      process.env.PUBLIC_URL +
                      "/dex_logo.png"
                    }
                  />
                </h1>
              </div>
            </div>
            <div>
              <div className="inputGroup inputGroup1">
                <label htmlFor="resetPassword">{t("password")}</label>
                <input
                  type="password"
                  name="resetPassword"
                  required
                  onFocus={this.onPasswordFocus}
                  onBlur={this.onPasswordBlur}
                  id="resetPassword"
                  className="password"
                  onChange={this.onChange}
                />
                <span className="indicator"></span>
              </div>
              <div className="inputGroup inputGroup2">
                <label htmlFor="password">{t("password_again")}</label>
                <input
                  type="password"
                  name="resetPasswordAgain"
                  required
                  onFocus={this.onPasswordFocus}
                  onBlur={this.onPasswordBlur}
                  id="resetPasswordAgain"
                  className="password"
                  onChange={this.onChange}
                />
              </div>
              <div className="inputGroup inputGroup2">
                <button
                  type="button"
                  id="verify"
                  onClick={this.resetPassword}
                  style={{ backgroundColor: "#cd4c6e" }}
                >
                  {t("change")}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (this.props.auth.isAuthenticated) {
      return (
        <div>
          <style>
            {`
            .wrapper, body, html {
              min-height: calc(100vh);
             }
              `}
          </style>
          <Layout></Layout>
        </div>
      );
    }
    return (
      <div className="loginBg" style={{ overflow: "hidden" }}>
        {this.props.auth.fetching && LoadingPanel}
        <LanguageSelect></LanguageSelect>

        <form
          id="style-14"
          autoComplete="new-password"
          onSubmit={this.onSubmit}
        >
          <div className="row">
            <div className="col-12 dexWord">
              <h1>
                <img
                  id="idLogoHeader"
                  alt=""
                  src={
                    window.location.origin +
                    process.env.PUBLIC_URL +
                    "/dex_logo.png"
                  }
                />
              </h1>
            </div>
          </div>
          <div>
            <div className="k-form-buttons" style={{ display: "grid" }}>
              <Button
                style={{ marginBottom: "10px", backgroundColor: "#0798cf" }}
                type={"button"}
                togglable={true}
                icon="login"
                onClick={this.setShowForm}
              >
                {t("bayiLogin")}
              </Button>
              <LoginAAD
                type={"button"}
                icon="login"
                auth={this.props.auth}
              ></LoginAAD>
              <hr></hr>
            </div>
            {this.props.auth.showForm ? (
              <div>
                <div className="inputGroup inputGroup1">
                  <label htmlFor="email1">{t("email")}</label>
                  <input
                    type="text"
                    required
                    id="email"
                    name="email"
                    onFocus={this.onEmailFocus}
                    onBlur={this.onEmailBlur}
                    onInput={this.onEmailInput}
                    autoComplete="new-password"
                    className="email"
                    maxLength="256"
                    onChange={this.onChange}
                  />
                  <span className="indicator"></span>
                </div>
                <div className="inputGroup inputGroup2">
                  <label htmlFor="password">{t("password")}</label>
                  <input
                    type="password"
                    name="password"
                    required
                    onFocus={this.onPasswordFocus}
                    autoComplete="new-password"
                    onBlur={this.onPasswordBlur}
                    id="password"
                    className="password"
                    onChange={this.onChange}
                  />
                </div>

                <CapsLock>
                  {(active) =>
                    active ? (
                      <div className="inputGroup inputGroup1 capslock">
                        <b>CapsLock {t("opened")}</b>
                      </div>
                    ) : (
                      ""
                    )
                  }
                </CapsLock>
                <div className="inputGroup inputGroup3">
                  <label htmlFor="mfa">{t("security_selection")} </label>
                  <select
                    name="mfa"
                    id="mfa"
                    value={this.props.loginReducer._login.mfa}
                    onChange={this.onChange}
                  >
                    <option value="Authenticator">Authenticator</option>
                    <option value="Email">Email</option>
                  </select>
                </div>
                <div className="inputGroup inputGroup4">
                  <button
                    type="submit"
                    id="login"
                    style={{ backgroundColor: "#0b0eab" }}
                  >
                    {t("login")}
                  </button>
                </div>
                <div className="inputGroup inputGroup4">
                  <button
                    type="button"
                    id="login"
                    onClick={this.forgotPassword}
                    style={{ backgroundColor: "#cd4c6e" }}
                  >
                    {t("forgot_password")}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    loginReducer: state.loginReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    login: bindActionCreators(login, dispatch),
    logout: bindActionCreators(logout, dispatch),
    setShowForm: bindActionCreators(setShowForm, dispatch),
    changeUser: bindActionCreators(changeUser, dispatch),
    changeMfa: bindActionCreators(changeMfa, dispatch),
    loginMfa: bindActionCreators(loginMfa, dispatch),
    setLogin: bindActionCreators(setLogin, dispatch),
    resetLogin: bindActionCreators(resetLogin, dispatch),
    forgotPassword: bindActionCreators(forgotPassword, dispatch),
    resetPassword: bindActionCreators(resetPassword, dispatch),
    updatePassword: bindActionCreators(updatePassword, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LoginAnimation));
