import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect } from "react";
//import { AZURE_ACTIVE_DOMAIN } from "../../config/globalVariables";
import { PublicClientApplication } from "@azure/msal-browser";
import { default as jwt_decode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loginAad,
  refreshJwtByAad,
} from "../../redux/actions/act-root/authActions";
import {
  loginRequest,
  msalConfig,
  silentRequest,
  tokenRequest,
} from "../../helpers/authConfig";

function LoginAAD(props) {
  const msalApp = new PublicClientApplication(msalConfig);
  const { t } = useTranslation();
  useEffect(() => {
    var jwttoken = localStorage.getItem("jwtToken");
    var azureToken = localStorage.getItem("aadToken");
    if (azureToken && jwttoken) {
      refreshToken(azureToken, jwttoken);
    }
    getAccounts();
  }, []);

  const refreshToken = async (azureToken, jwttoken) => {
    var now = new Date();
    var azureDecoded = jwt_decode(azureToken);
    var jwtDecoded = jwt_decode(jwttoken);
    var azureDate = new Date(azureDecoded.exp * 1000);
    var jwtDate = new Date(jwtDecoded.exp * 1000);
    var addDayAzureDate = new Date(azureDate.setDate(azureDate.getDate() + 90));
    if (now <= addDayAzureDate && now >= jwtDate) {
      var accessToken = await acquireToken();
      accessToken &&
        props.refreshJwtByAad({
          email: accessToken.account.username,
          token: accessToken.accessToken,
        });
    }
  };

  const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;
    return msie || msie11;
  };

  const getAccounts = () => {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = msalApp.getAllAccounts();
    if (currentAccounts === null) {
      console.error("No accounts detected!");
      return;
    } else if (currentAccounts.length > 1) {
      console.warn("Multiple accounts detected.");
      const account = msalApp.getAccountByUsername(currentAccounts[0].username);
      msalApp.setActiveAccount(account);
    } else if (currentAccounts.length === 1) {
      const account = msalApp.getAccountByUsername(currentAccounts[0].username);
      msalApp.setActiveAccount(account);
    }
  };

  const handleResponse = (response) => {
    if (response !== null) {
      msalApp.setActiveAccount(response.account);
    } else {
      getAccounts();
    }
  };

  const acquireToken = async () => {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    silentRequest.account = msalApp.getAccountByUsername(
      props.auth.account?.username
    );
    return msalApp.acquireTokenSilent(silentRequest).catch((error) => {
      console.warn(
        "silent token acquisition fails. acquiring token using interactive method"
      );
      if (error) {
        // fallback to interaction when silent call fails
        tokenRequest.account = msalApp.getAccountByUsername(
          props.auth.account?.username
        );

        return msalApp
          .acquireTokenPopup(tokenRequest)
          .then(handleResponse)
          .catch((err) => {
            console.error(err);
          });
      } else {
        console.warn(error);
      }
    });
  };

  const login = async () => {
    await msalApp.handleRedirectPromise();
    if (isIE()) {
      return msalApp.loginRedirect();
    }
    await msalApp
      .loginPopup(loginRequest)
      .then(handleResponse)
      .then(getUserProfile)
      .catch((err) => {
        console.error(err);
      });
  };

  const getUserProfile = async () => {
    var accessToken = await acquireToken();
    if (accessToken) {
      props.loginAad({
        email: accessToken.account.username,
        token: accessToken.accessToken,
      });
    }
  };

  return (
    <Button
      style={{ backgroundColor: "#375af1", color: "white", fontSize: "16px" }}
      type={props.type}
      icon={props.icon}
      onClick={login}
    >
      {t("personelLogin")}
    </Button>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loginAad: bindActionCreators(loginAad, dispatch),
    refreshJwtByAad: bindActionCreators(refreshJwtByAad, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginAAD);
