import { Row, Col } from "reactstrap";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DateInput } from "@progress/kendo-react-dateinputs";
import {
  DATALISTEVADESI,
  DATABRYSATISDEPARTMAN,
  DATAFATURAKURU,
  DATAFATURAVADESI,
  DATAODEMETIPI,
  DATAODEMEVADESI,
  DATASIPARISTURU,
  DATAPARABIRIMI,
} from "../../config/globalVariables";

export function OrderDateApproveProps({ orderData, maX_RDD_DATE }) {
  return (
    <Row>
      <Col lg="4" md="4" xs="12">
        <Input
          label="Sipariş No"
          name="brY_SIPARIS_ID"
          id="brY_SIPARIS_ID"
          style={{ width: "100%" }}
          disabled={true}
          value={orderData?.brY_SIPARIS_ID}
        ></Input>

        <Input
          name="bayI_KODU"
          label="Bayi Kodu"
          style={{ width: "100%" }}
          value={orderData?.bayI_KODU}
          readOnly={true}
        />

        <Input
          name="bayI_ADI"
          label="Bayi Adı"
          style={{ width: "100%" }}
          value={orderData?.bayI_ADI}
          readOnly={true}
        />

        <Input
          name="satiS_DEPARTMANI_KODU"
          label="Satış Departmanı"
          style={{ width: "100%" }}
          value={"UPG"}
          readOnly={true}
        />

        <Input
          name="satiS_DEPARTMANI_KODU"
          label="Ana Ürün Grubu"
          style={{ width: "100%" }}
          value={
            orderData?.satiS_DEPARTMANI_KODU !== null
              ? DATABRYSATISDEPARTMAN.find(
                  (a) => a.id === orderData?.satiS_DEPARTMANI_KODU
                )?.text
              : ""
          }
          readOnly={true}
        />

        <Input
          name="brY_KANAL"
          label="Marka"
          style={{ width: "100%" }}
          value={orderData?.brY_KANAL}
          readOnly={true}
        />

        <Input
          label="Onay Durum"
          name="onaY_DURUM"
          id="onaY_DURUM"
          style={{ width: "100%" }}
          disabled={true}
          value={orderData?.onaY_DURUM}
        ></Input>

        <Input
          label="Satış Onay Durum"
          name="satiS_ONAY_DURUM"
          id="satiS_ONAY_DURUM"
          style={{ width: "100%" }}
          value={orderData?.satiS_ONAY_DURUM}
          readOnly={true}
        ></Input>

        <DateInput
          label="Sipariş Giriş Tarihi"
          format="dd.MM.yyyy HH:mm"
          formatPlaceholder="formatPattern"
          value={new Date(orderData?.crE_DATE)}
          style={{ width: "100%" }}
          readOnly={true}
        />

        <Input
          name="parA_BIRIMI"
          label="Para Birimi"
          style={{ width: "100%" }}
          value={
            orderData?.parA_BIRIMI !== null
              ? DATAPARABIRIMI.find(
                  (a) => a.id === orderData?.parA_BIRIMI?.toString()
                )?.text
              : null
          }
          readOnly={true}
        />

        <Input
          label="Satış Temsilcisi"
          name="satiS_TEMSILCISI"
          id="satiS_TEMSILCISI"
          style={{ width: "100%" }}
          value={orderData?.satiS_TEMSILCISI}
          readOnly={true}
        ></Input>
      </Col>

      <Col lg={{ size: 4 }} md="4" xs="12">
        <Input
          name="sipariS_TURU"
          label="Sipariş Türü"
          style={{ width: "100%" }}
          value={
            orderData?.sipariS_TURU !== null
              ? DATASIPARISTURU.find(
                  (a) => a.id === orderData?.sipariS_TURU?.toString()
                )?.text
              : null
          }
          readOnly={true}
        />

        {orderData.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
          <Input
            name="listE_FIYATI_VADESI"
            label="Lis. Fiy. Vadesi"
            style={{ width: "100%" }}
            value={
              orderData?.listE_FIYATI_VADESI !== null
                ? DATALISTEVADESI.find(
                    (a) => a.id === orderData?.listE_FIYATI_VADESI?.toString()
                  )?.text
                : null
            }
            readOnly={true}
          />
        ) : undefined}

        {orderData?.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
          <Input
            name="odemE_VADESI_TIPI"
            label="Ödeme Vadesi Tipi"
            style={{ width: "100%" }}
            value={
              orderData?.odemE_VADESI_TIPI !== null
                ? DATAODEMEVADESI.find(
                    (a) => a.id === orderData?.odemE_VADESI_TIPI?.toString()
                  )?.text
                : null
            }
            readOnly={true}
          />
        ) : undefined}

        {orderData?.odemE_VADESI_TIPI === "FATURA_VADESI" ? (
          <Input
            name="faturA_VADESI"
            label="Ödeme Vadesi"
            style={{ width: "100%" }}
            value={
              orderData?.faturA_VADESI !== null
                ? DATAFATURAVADESI.find(
                    (a) => a.id === orderData?.faturA_VADESI?.toString()
                  )?.text
                : null
            }
            readOnly={true}
          />
        ) : undefined}

        <Input
          name="odemE_TIPI"
          label="Ödeme Tipi"
          style={{ width: "100%" }}
          value={
            orderData?.odemE_TIPI !== null
              ? DATAODEMETIPI.find(
                  (a) => a.id === orderData?.odemE_TIPI?.toString()
                )?.text
              : null
          }
          readOnly={true}
        />

        <Input
          name="faturA_KURU"
          label="Fatura Para Birimi"
          style={{ width: "100%" }}
          value={
            orderData?.faturA_KURU !== null
              ? DATAFATURAKURU.find(
                  (a) => a.id === orderData?.faturA_KURU?.toString()
                )?.text
              : null
          }
          readOnly={true}
        />
      </Col>

      <Col lg="4" md="4" xs="12">
        <Input
          label="Firma Yetkili Kişi"
          name="firmA_YETKILI_KISI"
          id="firmA_YETKILI_KISI"
          style={{ width: "100%" }}
          value={orderData?.firmA_YETKILI_KISI}
          readOnly={true}
        ></Input>

        <Input
          label="Sevk Edilecek Kişi"
          name="sevK_EDILECEK_KISI"
          id="sevK_EDILECEK_KISI"
          style={{ width: "100%" }}
          value={orderData?.sevK_EDILECEK_KISI}
          readOnly={true}
        ></Input>

        <Input
          label="İstenen Teslim Tarihi"
          name="sevK_TARIHI"
          value={
            orderData?.sevK_TARIHI !== null
              ? new Date(orderData?.sevK_TARIHI).toLocaleDateString()
              : null
          }
          style={{ width: "100%" }}
          readOnly={true}
        />

        <Input
          label="Ürün Max RDD Tarihi"
          name="maX_RDD_DATE"
          value={
            maX_RDD_DATE ? new Date(maX_RDD_DATE).toLocaleDateString() : null
          }
          style={{ width: "100%" }}
          readOnly={true}
        />

        <div>Sevk Notu</div>
        <TextArea
          placeholder="Sevk Notu"
          id="sevK_ADRESI"
          name="sevK_ADRESI"
          autoSize={false}
          style={{ width: "100%" }}
          value={orderData?.sevK_ADRESI}
          readOnly={true}
        ></TextArea>

        <div>Açıklama</div>
        <TextArea
          placeholder="Açıklama"
          id="aciklama"
          name="aciklama"
          autoSize={false}
          style={{ width: "100%" }}
          value={orderData?.aciklama}
          readOnly={true}
        ></TextArea>
      </Col>
    </Row>
  );
}
