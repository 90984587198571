import React, { Component } from "react";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export default class Homepage extends Component {
  componentDidMount() {
    window.location =
      window.location.origin + "/Dex?token=" + localStorage.getItem("jwtToken");
    // this.props.history.push("/Dex?token=" + localStorage.getItem("jwtToken"));
  }

  render() {
    return (
      <div className="col-12">
        <div style={{ paddingTop: "40%" }}>{LoadingPanel}</div>
      </div>
    );
  }
}
