import React, { Component } from "react";
import LoginAnimation from "./LoginAnimation";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";
import Login from "./LoginNew";

class App extends Component {
  render() {
    // return <LoginAnimation></LoginAnimation>;
    return <Login></Login>;
  }
}

export default withAITracking(reactPlugin, App);
