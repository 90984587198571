import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import "../../css/loginNew.css";
import {
  FormCheckboxWithURL,
  FormDropDownList,
  FormInput,
} from "../sys-form/FormComponents";
import {
  emailValidator,
  parseQueryString,
  requiredValidator,
} from "../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  forgotPassword,
  login,
  loginMfa,
  loginWithApproveKVKK,
  changeUser,
  changeMfa,
  logout,
  updatePassword,
  resetPassword,
} from "../../redux/actions/act-root/authActions";
import {
  resetLogin,
  setLogin,
} from "../../redux/actions/act-root/loginActions";
import alertify from "alertifyjs";
import LoginAAD from "./LoginAAD";
import { PROJECT_CODE } from "../../config/globalVariables";
import Layout from "./Layout";
import jwtDecode from "jwt-decode";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import renderTime from "../sys-toolbox/Timer";
import LanguageSelect from "../sys-toolbox/LanguageSelect";

const Login = (props) => {
  const { t } = useTranslation();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    mfaType: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      setUser(token);
    }
  }, [props.auth.isAuthenticated]);

  const setUser = (token) => {
    if (props.auth.userName === "") {
      props.changeUser(jwtDecode(token));
    }
  };

  const handleSubmit = (dataItem) => {
    const { email, password, mfa } = dataItem;
    setLoginData({ email, password, mfaType: mfa });
    props.login(email, password, mfa, PROJECT_CODE);
  };

  const onComplete = (e) => {
    props.resetLogin();
    props.changeMfa();
  };

  const handleForgotPassword = (e, formProps) => {
    e.preventDefault();
    var email = formProps.valueGetter("email");
    if (!email) {
      alertify.error("Lütfen email adresinizi giriniz.");
      return;
    }
    confirmAlert({
      title: "Uyarı!",
      message:
        "Mail adresinize şifrenizi yenileme e-postası gönderilecek. Devam etmek istiyor musunuz?.",
      buttons: [
        {
          label: "Evet",
          onClick: () => props.forgotPassword(email),
        },
        {
          label: "Hayır",
        },
      ],
    });
  };

  const handleMfaSubmit = (dataItem) => {
    const { verificationCode } = dataItem;
    props.loginMfa(
      loginData.email,
      loginData.password,
      verificationCode,
      loginData.mfaType,
      PROJECT_CODE
    );
  };

  const handleKvkkApprove = (dataItem) => {
    if (
      !dataItem.isOrtakligi ||
      !dataItem.dekorasyonYonetimi ||
      !dataItem.egitimSeminerSertifika ||
      !dataItem.memnuniyetYonetimi ||
      !dataItem.dijitallesmeYonetimi ||
      !dataItem.surdurulebilirlikYonetimi
    ) {
      alertify.error("Lütfen tüm onayları işaretleyiniz.");
      return;
    }
    props.loginWithApproveKVKK(
      loginData.email,
      loginData.password,
      loginData.mfaType,
      true
    );
  };

  const updatePassword = (dataItem) => {
    debugger;
    const { mail } = props.auth;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%^&*.])(?=.{8,})"
    );
    if (
      dataItem.resetPassword === null ||
      !strongRegex.test(dataItem.resetPassword)
    ) {
      alertify.error(
        "Lütfen içerisinde en az bir büyük harf,bir rakam, bir özel karakter bulunan ve en az 8 karakterden oluşan yeni şifrenizi giriniz."
      );
    } else if (dataItem.resetPassword !== dataItem.resetPasswordAgain) {
      alertify.error("Şifre ve Şifre Tekrar alanları aynı olmalıdır");
    } else {
      props.updatePassword(mail, dataItem.oldPassword, dataItem.resetPassword);
    }
  };

  const resetPassword = (dataItem) => {
    var params = parseQueryString();

    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%^&*.])(?=.{8,})"
    );
    if (
      dataItem.resetPassword === null ||
      !strongRegex.test(dataItem.resetPassword)
    ) {
      alertify.error(
        "Lütfen içerisinde en az bir büyük harf,bir rakam, bir özel karakter bulunan ve en az 8 karakterden oluşan yeni şifrenizi giriniz."
      );
    } else if (dataItem.resetPassword !== dataItem.resetPasswordAgain) {
      alertify.error("Şifre ve Şifre Tekrar alanları aynı olmalıdır");
    } else {
      props.resetPassword(
        params["uid"],
        parseInt(params["kid"]),
        dataItem.resetPassword,
        dataItem.resetPasswordAgain
      );
    }
  };

  const logout = (e) => {
    props.logout();
  };

  if (
    props.auth.isAuthenticated &&
    props.auth.userName !== "" &&
    (!props.auth.lastPasswordChange || props.auth.isDaikin === "DAIKIN")
  ) {
    return (
      <div>
        <style>
          {`
      .wrapper, body, html {
        min-height: calc(100vh);
       }
        `}
        </style>
        <Layout></Layout>
      </div>
    );
  }

  return (
    <div className="login-container">
      {props.auth.fetching && LoadingPanel}
      <LanguageSelect />
      <div className="login-box1">
        <img
          id="idLogoHeader"
          alt="Logo"
          src={
            window.location.origin + process.env.PUBLIC_URL + "/dex_logo.png"
          }
        />
        {window.location.pathname === "/reset-password" ? (
          <Form
            onSubmit={resetPassword}
            render={(formRenderProps) => (
              <FormElement style={{ textAlign: "center" }}>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"resetPassword"}
                    name={"resetPassword"}
                    label={<b>{t("password")}</b>}
                    type={"password"}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"resetPasswordAgain"}
                    name={"resetPasswordAgain"}
                    label={<b>{t("password_again")}</b>}
                    type={"password"}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <div
                  className="k-form-buttons"
                  style={{ display: "grid", gap: "10px" }}
                >
                  <Button
                    type={"submit"}
                    icon="check"
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "10px",
                      fontSize: "16px",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  >
                    {t("change")}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        ) : props.auth.isAuthenticated &&
          props.auth.lastPasswordChange &&
          props.auth.isDaikin !== "DAIKIN" ? (
          <Form
            onSubmit={updatePassword}
            render={(formRenderProps) => (
              <FormElement style={{ textAlign: "center" }}>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"oldPassword"}
                    name={"oldPassword"}
                    label={<b>{t("oldPassword")}</b>}
                    type={"password"}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"resetPassword"}
                    name={"resetPassword"}
                    label={<b>{t("password")}</b>}
                    type={"password"}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"resetPasswordAgain"}
                    name={"resetPasswordAgain"}
                    label={<b>{t("password_again")}</b>}
                    type={"password"}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <div
                  className="k-form-buttons"
                  style={{ display: "grid", gap: "10px" }}
                >
                  <Button
                    type={"submit"}
                    icon="check"
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "10px",
                      fontSize: "16px",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  >
                    {t("change")}
                  </Button>
                  <Button
                    type={"button"}
                    icon="arrow-left"
                    onClick={logout}
                    style={{
                      backgroundColor: "#9ca3af",
                      color: "white",
                      padding: "10px",
                      fontSize: "16px",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  >
                    {t("turnLogin")}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        ) : props.auth.isKVKKApproval ? (
          <Form
            onSubmit={handleKvkkApprove}
            render={(formRenderProps) => (
              <FormElement>
                <b style={{ color: "white" }}>
                  Giriş yapabilmek için lütfen onay veriniz.
                </b>

                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"isOrtakligi"}
                    name={"isOrtakligi"}
                    label={
                      "<a style='color:#8ec4ff;' target='_blank' href='/kvkk/Daikin_Is_Ortakligi_Aydinlatma_Metni.pdf'>Daikin İş Ortaklığı Aydınlatma Metni</a>'nde belirtilen hususları okudum ve kabul ediyorum."
                    }
                    component={FormCheckboxWithURL}
                  />
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"dekorasyonYonetimi"}
                    name={"dekorasyonYonetimi"}
                    label={
                      "<a style='color:#8ec4ff;' target='_blank' href='/kvkk/Dekorasyon_Yonetimi.pdf'>Dekorasyon Yönetimi</a>'nde belirtilen hususları okudum ve kabul ediyorum."
                    }
                    component={FormCheckboxWithURL}
                  />
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"egitimSeminerSertifika"}
                    name={"egitimSeminerSertifika"}
                    label={
                      "<a style='color:#8ec4ff;' target='_blank' href='/kvkk/Is_Ortaklari_Egitim_Seminer_ve_Sertifika_Surecleri.pdf'>İş Ortakları Eğitim, Seminer ve Sertifika Süreçleri</a>'nde belirtilen hususları okudum ve kabul ediyorum."
                    }
                    component={FormCheckboxWithURL}
                  />
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"memnuniyetYonetimi"}
                    name={"memnuniyetYonetimi"}
                    label={
                      "<a style='color:#8ec4ff;' target='_blank' href='/kvkk/Memnuniyet_Yonetimi.pdf'>Memnuniyet Yönetimi</a>'nde belirtilen hususları okudum ve kabul ediyorum."
                    }
                    component={FormCheckboxWithURL}
                  />
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"dijitallesmeYonetimi"}
                    name={"dijitallesmeYonetimi"}
                    label={
                      "<a style='color:#8ec4ff;' target='_blank' href='/kvkk/Sirket_Operasyonlarina_Yonelik_Surec_Gelistirme_ve_Dijitallesme_Yonetimi.pdf'>Şirket Operasyonlarına Yönetlik Süreç Geliştirme ve Dijitalleşme Yönetimi</a>'nde belirtilen hususları okudum ve kabul ediyorum."
                    }
                    component={FormCheckboxWithURL}
                  />
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"surdurulebilirlikYonetimi"}
                    name={"surdurulebilirlikYonetimi"}
                    label={
                      "<a style='color:#8ec4ff;' target='_blank' href='/kvkk/Surdurulebilirlik_ve_Proje_Yonetimi_Takibi_ve_Raporlama_Yonetimi.pdf'>Sürdürülebilirlik ve Proje Yönetimi Takibi ve Raporlama Yönetimi</a>'nde belirtilen hususları okudum ve kabul ediyorum."
                    }
                    component={FormCheckboxWithURL}
                  />
                </div>
                <div
                  className="k-form-buttons"
                  style={{ display: "grid", gap: "10px" }}
                >
                  <Button
                    type={"submit"}
                    icon="check"
                    style={{ backgroundColor: "#4CAF50", color: "white" }}
                  >
                    {t("approve")}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        ) : props.auth.isMfa ? (
          <Form
            onSubmit={handleMfaSubmit}
            render={(formRenderProps) => (
              <FormElement style={{ textAlign: "center" }}>
                <div>
                  <CountdownCircleTimer
                    strokeWidth={14}
                    isPlaying
                    duration={180}
                    colors={[["#00aeef", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                    onComplete={onComplete}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                </div>
                <div className={"k-form-field-wrap"}>
                  <Field
                    id={"verificationCode"}
                    name={"verificationCode"}
                    type={"password"}
                    maxLength={6}
                    label={<b>{t("verificationCode")}</b>}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <div
                  className="k-form-buttons"
                  style={{ display: "grid", gap: "10px" }}
                >
                  <Button
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    icon="check"
                    style={{ backgroundColor: "#4CAF50", color: "white" }}
                  >
                    {t("verify")}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        ) : (
          <>
            <div className="button-group">
              <Button
                style={{
                  backgroundColor: "#5BC0DE",
                  color: "#fff",
                  padding: "10px",
                  fontSize: "16px",
                  width: "100%",
                }}
                icon="login"
                onClick={() => setShowEmailForm(!showEmailForm)}
              >
                BAYİ KULLANICI GİRİŞİ
              </Button>
              <LoginAAD type={"button"} icon="login"></LoginAAD>
            </div>
            {showEmailForm && (
              <Form
                onSubmit={handleSubmit}
                initialValues={{ mfa: "Authenticator" }}
                render={(formRenderProps) => (
                  <FormElement>
                    {" "}
                    <div className={"k-form-field-wrap"}>
                      <Field
                        id={"email"}
                        name={"email"}
                        label={<b>{t("email")}</b>}
                        type={"email"}
                        component={FormInput}
                        validator={emailValidator}
                      />
                    </div>
                    <div className={"k-form-field-wrap"}>
                      <Field
                        id={"password"}
                        name={"password"}
                        label={<b>{t("password")}</b>}
                        type={"password"}
                        component={FormInput}
                        validator={requiredValidator}
                      />
                    </div>
                    <div className={"k-form-field-wrap"}>
                      <Field
                        id={"mfa"}
                        name={"mfa"}
                        label={t("security_selection")}
                        component={FormDropDownList}
                        data={["Authenticator", "Email"]}
                        validator={requiredValidator}
                      />
                    </div>
                    <div className="k-form-buttons">
                      <Button
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                        icon="login"
                        style={{
                          backgroundColor: "#4CAF50",
                          color: "white",
                          padding: "10px",
                          fontSize: "16px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      >
                        {t("login")}
                      </Button>
                      <Button
                        type={"button"}
                        icon="question"
                        onClick={(e) =>
                          handleForgotPassword(e, formRenderProps)
                        }
                        style={{
                          backgroundColor: "#F0AD4E",
                          color: "white",
                          padding: "10px",
                          fontSize: "16px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      >
                        {t("forgot_password")}
                      </Button>
                    </div>
                  </FormElement>
                )}
              ></Form>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = {
  login,
  logout,
  forgotPassword,
  loginMfa,
  loginWithApproveKVKK,
  changeUser,
  resetLogin,
  setLogin,
  changeMfa,
  updatePassword,
  resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
